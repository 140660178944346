<template>
  <div class="w-full">
    <div class="mt-5">
      <SearchFilterv2
        v-if="columns.length > 0 && columnsReady"
        :columns="columns"
        :loading="loading"
        @notify-filters="applyFilters"
      />
      <div v-else class="flex justify-center items-center my-auto">
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 36px"
            spin
          />
        </a-spin>
      </div>
      <!-- <SearchFilter
        v-if="columns.length > 0"
        :columns="columns"
        :loading="loading"
        @notify-filters="applyFilters"
      /> -->
    </div>
    <div class="w-full overflow-x-auto rounded-xl border border-gray-300"></div>
  </div>
</template>
<script>
import SearchFilter from "@/components/Dashboard/Management/SearchFilter";
import SearchFilterv2 from "@/components/Dashboard/Management/SearchFilterv2";

export default {
  components: {
    SearchFilter,
    SearchFilterv2,
  },
  data() {
    return {
      loading: false,
      columnsReady: false,
      columns: [],
      filterSet: {},
    };
  },
  created() {
    this.getColumnsFilter();
  },
  computed: {},
  watch: {},
  methods: {
    async getColumnsFilter() {
      this.loading = true;
      let { error, data } = await this.$api.getColumnsForFilter();
      if (error) {
        this.showToast(
          "error",
          "Error consultando la información para los filtros."
        );
        return;
      }
      this.columns = data;
      const promises = [];
      const indexes = [];
      this.columns.forEach((item, index) => {
        if (item.type === "SELECT") {
          promises.push(this.$api.getDataForFilter(item.name));
          indexes.push(index);
        }
      });
      Promise.allSettled(promises)
        .then((response) => {
          // Mapeo de las respuestas
          response.forEach((item, index) => {
            this.columns[indexes[index]].data = item.value?.data?.filter(
              (el) => el[this.columns[indexes[index]].name]
            );
            this.columnsReady = true;
          });

          this.loading = false;
        })
        .catch((error) => console.error(error));
    },
    applyFilters(ev) {
      this.$emit("notify-parent", ev);
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>
